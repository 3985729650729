var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "van-main" }, [
    _vm.$route.query.type != "all"
      ? _c(
          "div",
          { staticClass: "van-common-detail" },
          [
            _c(
              "van-cell-group",
              [
                _c("van-cell", {
                  attrs: {
                    title: "是否对外付汇：",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.appForm.whetherExchage_Name
                    )
                  }
                }),
                _vm.appForm.whetherExchage_Name === "是"
                  ? _c(
                      "div",
                      [
                        _c("van-cell", {
                          attrs: {
                            title: "原币币种：",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.appForm.currencyName
                            )
                          }
                        }),
                        _c("van-cell", {
                          attrs: {
                            title: "付款方式：",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.appForm.paymentMethodName
                            )
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("van-cell", {
                  attrs: {
                    title: "应付总额：",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.appForm.paymentAmount
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "付款方：",
                    value: _vm.utils.isEffectiveCommon(_vm.appForm.payerOrgName)
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "付款方账号：",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.appForm.paymentAccount
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "收款方：",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.appForm.beneficiaryOrgName
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "收款方账号：",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.appForm.accountsReceivable
                    )
                  }
                }),
                _vm.appForm.whetherExchage_Name === "是"
                  ? _c("van-cell", {
                      attrs: {
                        title: "待付金额：",
                        value: _vm.utils
                          .isEffectiveCommon(
                            ((_vm.appForm.paymentAmount || 0) * 1000 -
                              _vm.amountPaid) /
                              1000
                          )
                          .toFixed(2)
                      }
                    })
                  : _vm._e(),
                _c("van-cell", {
                  attrs: {
                    title: "本次实付金额：",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.appForm.amountActuallyPaid
                    )
                  }
                }),
                _vm.appForm.whetherExchage_Name === "是"
                  ? _c(
                      "div",
                      [
                        _c("van-cell", {
                          attrs: {
                            title: "付款汇率：",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.appForm.paymentRate
                            )
                          }
                        }),
                        _c("van-cell", {
                          attrs: {
                            title: "折人民币金额（元）：",
                            value:
                              _vm.utils.moneyFormat(
                                _vm.appForm.amountActuallyPaid * 1
                              ) *
                              (_vm.appForm.paymentRate * 1)
                          }
                        }),
                        _c("van-cell", {
                          attrs: {
                            title: "含银行手续费（元）：",
                            value: _vm.utils.moneyFormat(
                              _vm.appForm.bankCharges
                            )
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("van-cell", {
                  attrs: {
                    title: "付款日期：",
                    value: _vm.utils.isEffectiveCommon(_vm.appForm.paymentDate)
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "备注：",
                    value: _vm.utils.isEffectiveCommon(_vm.appForm.remarks)
                  }
                }),
                _c("file-list", {
                  attrs: {
                    "file-list": _vm.appForm.fileList,
                    title: "付款凭证："
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "van-common-detail" },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", [
                    _c("p", { staticClass: "lf" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.utils.statusFormat(
                              _vm.appForm.paymentStatus,
                              "paymentTypeList"
                            )
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("van-cell", {
                    attrs: {
                      title: "币种：",
                      value: _vm.utils.statusFormat(
                        _vm.appForm.whetherExchage === 0
                          ? "1"
                          : _vm.appForm.currency,
                        "OriginalCurrency"
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title:
                        "应付总额（" +
                        _vm.utils.statusFormat(
                          _vm.appForm.whetherExchage === 0
                            ? "1"
                            : _vm.appForm.currency,
                          "OriginalCurrency"
                        ) +
                        "）：",
                      value: _vm.utils.moneyFormat(_vm.appForm.paymentAmount)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title:
                        "已付金额（" +
                        _vm.utils.statusFormat(
                          _vm.appForm.whetherExchage === 0
                            ? "1"
                            : _vm.appForm.currency,
                          "OriginalCurrency"
                        ) +
                        "）：",
                      value: _vm.utils.moneyFormat(_vm.amountPaid / 1000)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title:
                        "待付金额（" +
                        _vm.utils.statusFormat(
                          _vm.appForm.whetherExchage === 0
                            ? "1"
                            : _vm.appForm.currency,
                          "OriginalCurrency"
                        ) +
                        "）：",
                      value: _vm.utils.moneyFormat(
                        ((_vm.appForm.paymentAmount || 0) * 1000 -
                          _vm.amountPaid) /
                          1000
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "van-common-detail" },
            [
              _c("van-cell", [
                _c(
                  "div",
                  { staticClass: "goods-table" },
                  _vm._l(_vm.appDownList, function(item, idx) {
                    return _c(
                      "table",
                      {
                        key: item.logisticsCabinetNoId,
                        staticClass: "inner-goods-table",
                        attrs: { border: "1" }
                      },
                      [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "header-th",
                              attrs: { colspan: "2" }
                            },
                            [_c("span", [_vm._v(_vm._s(idx + 1))])]
                          )
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("付款日期")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.utils.dataString(item.paymentDate))
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("付款方式")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  item.paymentMethod,
                                  "PaymentMethod"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("付款金额（元）")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.moneyFormat(item.amountActuallyPaid)
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("收款方")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.appForm.beneficiaryOrgName))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("付款方账号")]),
                          _c("td", [_vm._v(_vm._s(_vm.appForm.paymentAccount))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("备注")]),
                          _c("td", [_vm._v(_vm._s(item.remarks))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("附件")]),
                          _c(
                            "td",
                            [
                              _c("file-list", {
                                attrs: {
                                  "file-list": item.fileDetailInfoVoList,
                                  title: ""
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("状态")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  item.resultStatus,
                                  "resultStatus"
                                )
                              )
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }