<template>
  <div class="van-main">
    <div v-if="$route.query.type != 'all'" class="van-common-detail">
      <van-cell-group>
        <van-cell title="是否对外付汇：" :value="utils.isEffectiveCommon(appForm.whetherExchage_Name)" />
        <div v-if="appForm.whetherExchage_Name === '是'">
          <van-cell title="原币币种：" :value="utils.isEffectiveCommon(appForm.currencyName)" />
          <van-cell title="付款方式：" :value="utils.isEffectiveCommon(appForm.paymentMethodName)" />
        </div>
        <van-cell title="应付总额：" :value="utils.isEffectiveCommon(appForm.paymentAmount)" />
        <van-cell title="付款方：" :value="utils.isEffectiveCommon(appForm.payerOrgName)" />
        <van-cell title="付款方账号：" :value="utils.isEffectiveCommon(appForm.paymentAccount)" />
        <van-cell title="收款方：" :value="utils.isEffectiveCommon(appForm.beneficiaryOrgName)" />
        <van-cell title="收款方账号：" :value="utils.isEffectiveCommon(appForm.accountsReceivable)" />
        <van-cell v-if="appForm.whetherExchage_Name === '是'" title="待付金额：" :value="utils.isEffectiveCommon((((appForm.paymentAmount || 0) * 1000) - amountPaid) / 1000).toFixed(2)" />
        <van-cell title="本次实付金额：" :value="utils.isEffectiveCommon(appForm.amountActuallyPaid)" />
        <div v-if="appForm.whetherExchage_Name === '是'">
          <van-cell title="付款汇率：" :value="utils.isEffectiveCommon(appForm.paymentRate)" />
          <van-cell title="折人民币金额（元）：" :value="utils.moneyFormat(appForm.amountActuallyPaid * 1) * (appForm.paymentRate * 1)" />
          <van-cell title="含银行手续费（元）：" :value="utils.moneyFormat(appForm.bankCharges)" />
        </div>
        <van-cell title="付款日期：" :value="utils.isEffectiveCommon(appForm.paymentDate)" />
        <van-cell title="备注：" :value="utils.isEffectiveCommon(appForm.remarks)" />
        <file-list :file-list="appForm.fileList" title="付款凭证：" />
      </van-cell-group>
    </div>
    <div v-else>
      <div class="van-common-detail">
        <van-cell-group>
          <van-cell>
            <p class="lf">
              {{ utils.statusFormat(appForm.paymentStatus, 'paymentTypeList') }}
            </p>
          </van-cell>
          <van-cell title="币种：" :value="utils.statusFormat(appForm.whetherExchage===0?'1':appForm.currency, 'OriginalCurrency')" />
          <van-cell :title="`应付总额（${utils.statusFormat(appForm.whetherExchage===0?'1':appForm.currency, 'OriginalCurrency')}）：`" :value="utils.moneyFormat(appForm.paymentAmount)" />
          <van-cell :title="`已付金额（${utils.statusFormat(appForm.whetherExchage===0?'1':appForm.currency, 'OriginalCurrency')}）：`" :value="utils.moneyFormat(amountPaid / 1000)" />
          <van-cell :title="`待付金额（${utils.statusFormat(appForm.whetherExchage===0?'1':appForm.currency, 'OriginalCurrency')}）：`" :value="utils.moneyFormat((((appForm.paymentAmount || 0) * 1000) - amountPaid) / 1000)" />
        </van-cell-group>
      </div>
      <div class="van-common-detail">
        <van-cell>
          <div class="goods-table">
            <table v-for="(item,idx) in appDownList" :key="item.logisticsCabinetNoId" class="inner-goods-table" border="1">
              <tr>
                <th colspan="2" class="header-th">
                  <span>{{ idx+1 }}</span>
                </th>
              </tr>
              <tr>
                <td>付款日期</td><td>{{ utils.dataString(item.paymentDate) }}</td>
              </tr>
              <tr>
                <td>付款方式</td><td>{{ utils.statusFormat(item.paymentMethod, 'PaymentMethod') }}</td>
              </tr>
              <tr>
                <td>付款金额（元）</td><td>{{ utils.moneyFormat(item.amountActuallyPaid) }}</td>
              </tr>
              <tr>
                <td>收款方</td><td>{{ appForm.beneficiaryOrgName }}</td>
              </tr>
              <tr>
                <td>付款方账号</td><td>{{ appForm.paymentAccount }}</td>
              </tr>
              <tr>
                <td>备注</td><td>{{ item.remarks }}</td>
              </tr>
              <tr>
                <td>附件</td>
                <td>
                  <file-list :file-list="item.fileDetailInfoVoList" title="" />
                  <!--                  <div v-for="(item, index) in item.fileDetailInfoVoList" :key="index">-->
                  <!--                    <a download :href="_.get(item, 'fileUrl')">{{ _.get(item, 'fileName', '-') }}</a>-->
                  <!--                  </div>-->
                </td>
              </tr>
              <tr>
                <td>状态</td><td>{{ utils.statusFormat(item.resultStatus, 'resultStatus') }}</td>
              </tr>
            </table>
          </div>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Field, Form, Col, Row, Tab, Tabs } from 'vant'
import fileList from '@/components/file-list'
export default {
  name: 'AttachmentList',
  components: {
    fileList,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props: {
    // 所属模块
    detailTables: {
      type: [Array],
      default () {
        return []
      }
    }
  },
  data () {
    return {
      appForm: {
        fileName: '',
        pageNum: 1,
        pageSize: 10,
        moduleName: ''
      },
      loading: false,
      finished: false,
      appDownList: [],
      amountPaid: 0
    }
  },
  created () {
    this.getInfoById()
  },
  methods: {
    // 获取详情
    getInfoById () {
      this.api.payment.getInfoById(this.$route.query.detailId).then(res => {
        this.appForm = res.data.value || {}
        this.getInfoByPaymentId()
      }).finally(() => {
      })
    },
    getInfoByPaymentId () {
      this.amountPaid = 0
      this.api.payment.getInfoByPaymentId(this.$route.query.paymentId).then(res => {
        this.appDownList = res.data.value || []
        this.appDownList.forEach(item => {
          if (item.resultStatus === 3) {
            this.amountPaid = this.amountPaid + (item.amountActuallyPaid * 1000)
          }
        })
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less">
</style>
